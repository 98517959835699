import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { BiMap} from "@react-icons/all-files/bi/BiMap";
import { FiPhoneCall} from "@react-icons/all-files/fi/FiPhoneCall";
import { FiMail} from "@react-icons/all-files/fi/FiMail";
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const ContactInformationPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Contact endodontics pc Indianapolis IN</title>
        <meta name="description" content="Contact Indianapolis IN Endodontist Dr. Broady for your Endodontics needs. 317-846-4980" />
        <link rel="canonical" href="https://meridianendoindy.com/contact-us/contact-information-office-map/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Contact endodontics pc Indianapolis IN" />
        <meta name="og:description" content="Contact Indianapolis IN Endodontist Dr. Broady for your Endodontics needs. 317-846-4980" />
        <meta property="og:url" content="https://meridianendoindy.com/contact-us/contact-information-office-map/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Contact endodontics pc Indianapolis IN" />
        <meta name="twitter:description" content="Contact Indianapolis IN Endodontist Dr. Broady for your Endodontics needs. 317-846-4980" />
        <meta name="twitter:url" content="https://meridianendoindy.com/contact-us/contact-information-office-map/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="contact-us-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="contact-us-hero-section">
          <Container>
            <div className="col-lg-6 left-content">
              <h1 className="primary-text hero-heading">
                Contact Meridian Endodontics
              </h1>
              <p className="hero-text mb-3">
                There are many ways to communicate with our office. Please choose the method most convenient to you.
              </p>
              <Link to="#contact-us-section-1">
                <button className="hero-section-btn"> Schedule a Consultation</button>
              </Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="men having a conversion in his phone"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="contact-us-section-1">
        <Container>
          <div className="col-lg-12 text-center">
            <h2 className="primary-text">Contact Meridian Endodontics</h2>
            <div className="bottom-border"></div>
            <p id="heading-p">There are many ways to communicate with our office. Please choose the method most convenient to you.</p>
          </div>
          <div className="col-lg-8">
            <div className="form-container">
              <h4 className="primary-text"> Contact Us Today</h4>
              <p>If you are an existing patient, this contact form should not be utilized for communicating private health information.</p>
              <form id="homepage-form" method="POST" action="https://usebasin.com/f/f5c1f073ca48">
                <div className="col-lg-12">
                  <input type="text" placeholder="Your Name*" name="name" required/>
                </div>
                <div className="col-lg-12">
                  <input type="email" placeholder="Your Email Address*" name="email" required/>
                </div>
                <div className="col-lg-12">
                  <input type="tel" placeholder="Your Phone Number*" name="telephone" required/>
                </div>
                <div className="col-lg-12">
                  <textarea placeholder="Comments/Questions*" name="comments" required></textarea>
                </div>
                <button type="submit" id="homepage-form-btn"> Submit </button>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contact-info">
              <h4 className="primary-text"><span><BiMap /></span>Business Address </h4>
              <p>9002 North Meridian St, Suite 201 Indianapolis, IN 46260</p>
              <h4 className="primary-text"><span><FiPhoneCall/></span>Telephone </h4>
              <p>Phone: <strong><a href="tel:3178464980">317-846-4980</a></strong></p>
              <p>Fax:  <strong><a href="tel:3178464982">317-846-4982</a></strong></p>
              <h4 className="primary-text"><span><FiMail /></span>Email Us</h4>
              <p id="email-p"><a href="mailto:office@meridianendoindy.com">office@meridianendoindy.com</a></p>
              <p>Please use this for general practice inquiries only – specific patient care questions must be addressed with your doctor during a consultation appointment. Click on the address below to send us an email.</p>
            </div>
          </div>
          <div className="col-lg-12 mt-5">
            <h4 id="map-header">Online Map & Driving Directions</h4>
            <p>If this is the first time you have visited our Indianapolis office, the mapping service below will assist you in finding our location. Simply fill out the form below, and you will be presented with directions that include a map, the travel time, and distance. If you need any additional information, please contact us at 317-846-4980.</p>
            <div className="map-frame">
              <iframe width="100%" height="600px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?origin=mfe&pb=!1m6!3m3!1m2!2s9002+North+Meridian+Street,+Suite+201,+Indianapolis,+IN+46260!4s5662226363560454325!5e0!6i15"></iframe>
              <a href="https://www.google.com/maps/place/Steffel+%26+Leddy+Endodontics/@39.9184147,-86.1588411,15z/data=!4m5!3m4!1s0x0:0x4e9444e0bddf68b5!8m2!3d39.9184147!4d-86.1588411" target="_blank" className="text-center"><p>Having trouble viewing map? Click here. </p></a>
            </div>
          </div>
          <div className="col-lg-12 map-form-container">
          <form id="map-form" target="_blank" method="get" action="https://maps.google.com/maps" data-track-event="set" data-track-category="Map Directions" data-track-action="Get Directions" data-track-label="Office Location" _lpchecked="1">
              <div className="row">
                <div className="col-lg-3">
                  <h5>Enter Your Starting Address:</h5>
                </div>
                <div className="col-lg-6">
                  <input type="text" placeholder="Address, City, State or Zip Code" id="mapsUsSaddr" name="saddr"/>
                </div>
                <div className="col-lg-3">
                  <button type="submit">Get Directions</button>
                  <input type="hidden" name="daddr" value="9002 North Meridian Street, Suite 201, Indianapolis, IN 46260" />
                </div>
              </div>
            </form>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default ContactInformationPage

export const query = graphql`
  query ContactInformationPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "contact-us-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
